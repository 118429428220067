.radio_group {
    margin-bottom: 2.4rem;
}

.radio_group .radio_items {
    display              : grid;
    grid-template-columns: repeat(3, 1fr);
    gap                  : 1.2rem;
}

.radio_group .radio_item input[type='radio'],
.radio_group .radio_item label {
    vertical-align: middle;
}

.radio_group .radio_item input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance   : none;
    appearance        : none;
    width             : 1.6rem;
    height            : 1.6rem;
    background-color  : var(--white);
    border            : 1px solid var(--gray-3);
    border-radius     : 50%;
    position: relative;
}

.radio_group .radio_item input:checked[type='radio'] {
    border-color: var(--black);
}

.radio_group .radio_item input:checked[type='radio']::after {
    content         : '';
    position        : absolute;
    top             : 50%;
    left            : 50%;
    transform       : translate(-50%, -50%);
    width           : 1rem;
    height          : 1rem;
    border-radius   : 50%;
    background-color: var(--black);
}

.radio_group .radio_item label {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-left: .8rem;
    color: var(--gray-6);
}





.form-check-input {
    width              : 1em;
    height             : 1em;
    margin-top         : .25em;
    vertical-align     : top;
    background-color   : #fff;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : contain;
    border             : 1px solid rgba(0, 0, 0, .25);
    border-radius      : 50%;
    -webkit-appearance : none;
    -moz-appearance    : none;
    appearance         : none;
}

.form-check-input:checked {
    background-color: #0d6efd;
    border-color    : #0d6efd;
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}