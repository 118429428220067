.default_info .edit_btn {
    align-items: center;
    display    : flex;
}

.profile_info .default_info .edit_btn {
    box-shadow     : inset 0px -1px 0px var(--gray-2);
    width          : 100%;
    justify-content: space-between;
    left           : 0;
    padding        : 0 0 2rem;
    margin         : 4rem 0 3rem;
}

.default_info .edit_btn a {
    color: var(--gray-2);
}

.profile_info .default_info .grid_container {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap              : 3rem;
    column-gap           : 2rem;
}

.body_text {
    text-overflow: ellipsis;
    overflow     : hidden;
}

.profile_buttons {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding-top    : 2rem;
}

.profile_buttons .btn_text {
    color         : var(--gray-3);
    text-transform: capitalize;
}

.default_info .status {
    margin-bottom: 0;
}

.default_info .btn_text {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .08em;
    line-height: 2.5rem;
    text-transform: capitalize;
    color:var(--gray-2)
}