.profile_view {
  width: 60rem;
}
/* ==upload picture== */
.upload-picture {
  text-align : center;
  padding-top: 5rem;
}

.default_info .edit_btn {
  width          : 100%;
  justify-content: space-between;
}

.default_info .edit_btn>* {
  display    : flex;
  align-items: center;
}

.default_info .edit_btn>*>*:not(:last-child) {
  margin-right: .8rem;
}

.default_info .edit_btn svg {
  width : 1.8rem;
  height: 1.8rem;
}

.default_info .edit_btn>*:nth-child(2) svg>g>path:nth-child(2) {
  fill: var(--gray-5);
}

.default_info .edit_btn>*:nth-child(2) svg:hover>g>path:nth-child(2) {
  fill: var(--black);
}


@media (orientation: portrait) {
  .profile_info .default_info .grid_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .profile_info .default_info .grid_container .info_content:nth-child(3) {
    grid-column: 1 / -1;
  }
  .profile_info .default_info .grid_container.form_wrapper {
    grid-template-columns: 1fr;
  }
}