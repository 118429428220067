.input_field_label {
    display      : block;
    font-weight  : 600;
    font-size    : 1.6rem;
    line-height  : 2rem;
    color        : var(--gray-6);
    text-transform: capitalize;
    margin-bottom: 1.4rem;
}

.input_field {
    font-weight     : 400;
    font-size       : 1.4rem;
    line-height     : 1.8rem;
    color           : var(--gray-4);
    padding         : 1.5rem 2rem;
    background-color: transparent;
    border          : 1px solid var(--gray-6);
    border-radius   : 5px;
    margin-bottom   : 2.4rem;
    width           : 100%;
}

.input_field::placeholder {
    color: var(--gray-);
}

.input_field:hover,
.input_field:focus-visible {
    border: 1px solid var(--gray-3);
}

select {
    appearance         : none;
    background-image   : url(../../../../Assets/select-down-arrow.svg);
    background-size    : 2rem 2rem;
    background-position: right 1.2rem center;
    background-repeat  : no-repeat;
}

.logo_wrapper {
    display        : flex;
    justify-content: center;
    margin-bottom  : 4rem;
}