.modal-backdrop {
    position        : fixed;
    top             : 0;
    left            : 0;
    width           : 100vw;
    height          : 100vh;
    background-color: rgba(0, 0, 0, .7);
    z-index         : 1040;
    display         : flex;
    align-items     : center;
    justify-content : center;
}

.modal-content {
    background-color: #fff;
    border-radius   : 15px;
    padding         : 5rem;
    min-height      : 50vh;
    width           : 100%;
    max-width       : 40vw;
}

.modal-main {
    padding-top       : 3rem;
    max-height        : 70vh;
    overflow-x        : hidden;
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
}

.modal-main::-webkit-scrollbar {
    display: none;
}