.modal-header {
    border-bottom: 1px solid var(--gray-5);
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.close-button {
    border: 1px solid var(--gray-5);
    border-radius: 50%;
    width: 3vh;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-button svg {
    fill: var(--gray-5);
}
.close-button:hover svg {
    fill: var(--white);
}

.close-button:hover {
    background-color: var(--black);
    color: var(--white);
}