.crud_view_content {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
}

.crud_view_content h1 {
    font-size    : 1.8rem;
    line-height  : 2.4rem;
    margin-bottom: 1.2rem;
}

.crud_view_content p,
.crud_view_content img {
    margin-bottom: 3.2rem;
}

.crud_view_content p {
    font-weight: 500;
    color      : var(--gray-6);
}

.crud_view_content img {
    width    : 100%;
    max-width: 50%;
}