.cancel,
.submit,
.delete {
    padding       : 1.5rem 4.9rem;
    display       : inline-block;
    font-weight   : 700;
    font-size     : 1.5rem;
    line-height   : 1.7rem;
    text-align    : center;
    cursor        : pointer;
    text-transform: uppercase;
    border-radius : 5px;
    width: 100%;
}

.cancel {
    border        : 1px solid var(--gray-6);
}
.cancel:hover {
    background-color: var(--gray-6);
}

.submit {
    border        : 1px solid var(--gray-6);
    color     : var(--gray-6);
}

.submit:hover {
    background: var(--gray-6);
    color     : var(--primary-color);
}

.delete {
    background-color: var(--dark-red);
    color:var(--white);
}

.delete:hover {
    background-color: var(--bright-red);
}
